import type { Environment } from '@dextools/core';
import type { ChainApi } from '@dextools/blockchains';
import { CHAIN_API_AND_PORT_LIST } from '@dextools/blockchains';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
export { CHAIN_API_LIST as chains, CHAIN_API_AND_PORT_LIST as chainsData } from '@dextools/blockchains';
// IMPORTANT: this const and the related types are defined in this file to prevent any conflict with the file replacement
// done by Angular CLI when generating the different builds (see angular.json)
export const APP_VERSION = '2.110.2';
export const appTitle = 'DEXTools';
export const externalLink = false;

export type ApiUrlKey = `api_${ChainApi}_url`;

const domain = 'localhost';
const appScope = '';
const host = `http://${domain}`;
const appUrl = `${host}${appScope}`;
const backUrl = `${host}:3030/back`;
//Todo
const shared = `${host}:3020`;
const cfProxyUrl = `${host}:3020/cz`;
const coreApiURL = `${host}`;

export const blacklistedWindowNames = ['refreshingWindow'];

const apiUrls: Record<ApiUrlKey, string> = {} as unknown as Record<ApiUrlKey, string>;

for (const current of CHAIN_API_AND_PORT_LIST.values()) {
  apiUrls[`api_${current[0]}_url`] = `${host}:${current[1]}/api`;
}

/**
 * Returns the API path for the given chain
 *
 * @param chain - the chain
 * @param apiUrlsObj - the API URLs object
 * @returns the API path
 */
function getApiPath(chain: ChainApi, apiUrlsObj: Record<ApiUrlKey, string>) {
  if (/ether/.test(chain)) {
    return apiUrlsObj.api_ethereum_url;
  }

  const apiUrl: ApiUrlKey = `api_${chain}_url`;
  return apiUrlsObj[apiUrl];
}

/**
 * Returns the paths available for admin users
 *
 * @param apiUrlsObj - the API URLs object
 * @returns the paths available for admin users
 */
export function getAdminPaths(apiUrlsObj: Record<ApiUrlKey, string>) {
  return {
    token: {
      update: (chain: ChainApi, pairContract: string, tokenContract: string, txCount?: number, volume?: number) => {
        const volumeParam = volume ? `&volume=${volume}` : '';
        const txCountParam = txCount ? `&txCount=${txCount}` : '';
        return `${getApiPath(chain, apiUrlsObj)}/token/holders?pair=${pairContract}&token=${tokenContract}${volumeParam}${txCountParam}`;
      },

      audit: (chain: ChainApi, tokenContract: string, force = false) =>
        `${getApiPath(chain, apiUrlsObj)}/token/audit?token=${tokenContract}&force=${force}`,
    },
    tokenNews: {
      save: `${getApiPath('ethereum', apiUrlsObj)}/token-news/save`,
      all: `${getApiPath('ethereum', apiUrlsObj)}/token-news/all`,
      status: `${getApiPath('ethereum', apiUrlsObj)}/token-news/status`,
    },
  } as const;
}

class EnvironmentImpl implements Environment {
  public appTitle = appTitle;
  public externalLink = externalLink;
  public allowAdmin = true;
  public app_scope = appScope;
  public appUrl = `${appUrl}`;
  public host = `${host}`;
  public assets_url = `${host}${appScope}/assets`;
  public appLogo = `${host}${appScope}/assets/img/logo/dextools_logo_header_125.png`;
  public apiUrls = { ...apiUrls };
  public back_url = `${backUrl}`;
  public meta_url = `${host}:3030/meta`;
  public ws_url = `ws://${domain}:8050`;
  public paths = getAdminPaths(apiUrls);
  public production = false;
  public whitelistedUrls = [] as string[];
  public siteId = '';
  public appVersion = APP_VERSION;
  public shared_url = `${shared}`;
  public cf_proxy_url = cfProxyUrl;
  public local_prod_url = null;
  public core_api_url = coreApiURL;
}

export const environment = new EnvironmentImpl();
